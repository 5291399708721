import {
  ApprovalFlowStep,
  ApprovalStep,
  Assignee,
} from '@ulysses-inc/harami_api_client'

// prettier-ignore
export const ActionTypes = {
  // get approval
  REQUEST_GET_APPROVAL: 'harami/approvals/REQUEST_GET_APPROVAL',
  SUCCESS_GET_APPROVAL: 'harami/approvals/SUCCESS_GET_APPROVAL',
  ERROR_GET_APPROVAL: 'harami/approvals/ERROR_GET_APPROVAL',
  RESET_APPROVAL: 'harami/approvals/RESET_APPROVAL',

  // delete approval
  REQUEST_DELETE_APPROVAL: 'harami/approvals/REQUEST_DELETE_APPROVAL',
  SUCCESS_DELETE_APPROVAL: 'harami/approvals/SUCCESS_DELETE_APPROVAL',
  ERROR_DELETE_APPROVAL: 'harami/approvals/ERROR_DELETE_APPROVAL',

  // add approval step
  REQUEST_ADD_APPROVAL_STEP: 'harami/approvals/REQUEST_ADD_APPROVAL_STEP',
  SUCCESS_ADD_APPROVAL_STEP: 'harami/approvals/SUCCESS_ADD_APPROVAL_STEP',
  ERROR_ADD_APPROVAL_STEP: 'harami/approvals/ERROR_ADD_APPROVAL_STEP',

  // add approval report
  REQUEST_ADD_APPROVAL_REPORT: 'harami/approvals/REQUEST_ADD_APPROVAL_REPORT',
  SUCCESS_ADD_APPROVAL_REPORT: 'harami/approvals/SUCCESS_ADD_APPROVAL_REPORT',
  ERROR_ADD_APPROVAL_REPORT: 'harami/approvals/ERROR_ADD_APPROVAL_REPORT',

  // get approval reports
  REQUEST_GET_APPROVAL_REPORTS: 'harami/approvals/REQUEST_GET_APPROVAL_REPORTS',
  SUCCESS_GET_APPROVAL_REPORTS: 'harami/approvals/SUCCESS_GET_APPROVAL_REPORTS',
  ERROR_GET_APPROVAL_REPORTS: 'harami/approvals/ERROR_GET_APPROVAL_REPORTS',
  REQUEST_CHANGE_APPROVAL_REPORTS_PAGE: 'harami/approvals/REQUEST_CHANGE_APPROVAL_REPORTS_PAGE',
  REQUEST_CHANGE_APPROVAL_REPORTS_SIZE: 'harami/approvals/REQUEST_CHANGE_APPROVAL_REPORTS_SIZE',
  UPDATE_PAGINATION: 'harami/approvals/UPDATE_PAGINATION',

  // bulk approval reports
  REQUEST_BULK_APPROVE_REPORTS: 'harami/approvals/REQUEST_BULK_APPROVE_REPORTS',
  SUCCESS_BULK_APPROVE_REPORTS: 'harami/approvals/SUCCESS_BULK_APPROVE_REPORTS',
  ERROR_BULK_APPROVE_REPORTS: 'harami/approvals/ERROR_BULK_APPROVE_REPORTS',
} as const

export type DeleteApprovalArg = {
  reportUUID: string
  approvalFlowId: number
  url: string
  approvalUUID: string
  comment: string
  currentStep: ApprovalStep
  firstStep: ApprovalFlowStep
  assignees: Assignee[]
  reportName: string
  placeName: string
  reportDate: Date
  remandedUserName: string
}

export interface GetApprovalReportsFilter {
  reportName?: { $like: string }
  placeNodeId?: { $in: string[] }
  reportDate?: { $gte: Date; $lte: Date }
  isInvalid?: { $in: number[] }
  assignee?: {
    user?: { $like: string }
  }
}
