import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { ReportNodesDict, ReportPagesDict } from 'src/exShared/types/types'
import { computeIfAbsent } from 'src/util/map'
import { isRepeatedSectionMaster } from './grid/utils/isRepeatedSectionMaster'

type Result = {
  /**
   * 繰り返しセクションのインスタンスノードの位置補正済みの ReportPagesDict
   */
  resultPagesDict: ReportPagesDict
  /**
   * 繰り返しセクションのインスタンスノードの位置補正済みの ReportNodesDict
   */
  resultNodesDict: ReportNodesDict
}

type IdPathToTheNode = number[]

/**
 * 繰り返しセクションのインスタンスノードを適切な位置に挿入するために使用する関数。
 * 本来、繰り返しセクションのインスタンスノードは、繰り返しセクションのオリジナルノードの直後の兄弟ノードとして挿入される。
 *
 * しかし、
 * 条件分岐の配下に繰り返しセクションが存在する場合、繰り返しセクションのオリジナルノードの「親」＝条件分岐ノードより上位階層の
 * セクション or ページの配下のノードとしてインスタンスノードが挿入されてしまうようである。
 * これにより、レポート結果画面などでの表示順が意図したものにならない。
 * このようなデータを是正するため、
 * 繰り返しセクションのインスタンスノードにつき、オリジナルノードと親が異なっている場合に、
 * オリジナルノード直後の兄弟として挿入し直す補正を行っている
 *
 * @see https://kaminashi.atlassian.net/browse/HPB-5205
 * @see https://www.notion.so/7c49aa2bbe744298aedf655c9a990fc5
 *
 * @param allPagesDict このオブジェクトを書き換えるのではなく、deep copy をとってから補正したものを返す。
 * @param allNodesDict 同上
 * @returns
 */
export const fixPositionsOfRepeatedSectionInstances = (
  allPagesDict: ReportPagesDict,
  allNodesDict: ReportNodesDict,
): Result => {
  const nodeTreeStructureInfo = new NodeTreeStructureInfo(
    allPagesDict,
    allNodesDict,
  )

  const instanceNodesByOriginalNodeId = new Map<number, ReportNodeSchema[]>()
  for (const node of nodeTreeStructureInfo.orderedNodes) {
    if (node.type !== TemplateNodeTypeEnum.Section) continue
    if (node.section?.isRepeat !== 1 || !node.section?.originalNodeUuid) {
      continue
    }
    // わかりやすくするために変数名を変える
    const instanceNode = node
    const originalNodeUUID = node.section?.originalNodeUuid

    // マスターノードの「候補」（正確には、マスタノードの候補へのパス)を取得
    const idPathToTheNodes = nodeTreeStructureInfo.getIdPathTo(originalNodeUUID)
    if (!idPathToTheNodes) {
      continue
    }
    // インスタンスノードの親がページの場合、「候補」は１つしかないはずなので、その「候補」を採用
    if (nodeTreeStructureInfo.getParentPage(instanceNode.id)) {
      const idPathToTheNode = idPathToTheNodes[0]
      if (!idPathToTheNode) {
        continue
      }
      const originalNodeId = idPathToTheNode.at(-1)
      if (!originalNodeId) {
        continue
      }
      computeIfAbsent(
        instanceNodesByOriginalNodeId,
        originalNodeId,
        () => [],
      ).push(instanceNode)
      continue
    }
    // インスタンスノードの親がページ以外の場合、（より正確には繰り返しセクションである場合）「候補」が複数ありうるので、候補から絞り込む必要がある。
    // uuid が合致するマスターノードのうち、そのノードへの idPath に、インスタンスノードの親を含むものが採用される

    const parentNode = nodeTreeStructureInfo.getParentNode(instanceNode.id)
    if (!parentNode) {
      continue
    }
    const idPathToTheNode = idPathToTheNodes.filter(idPathToTheNode =>
      idPathToTheNode.includes(parentNode.id),
    )[0]
    if (!idPathToTheNode) {
      continue
    }
    const originalNodeId = idPathToTheNode.at(-1)
    if (!originalNodeId) {
      continue
    }
    computeIfAbsent(
      instanceNodesByOriginalNodeId,
      originalNodeId,
      () => [],
    ).push(instanceNode)
  }

  for (const [
    originalNodeId,
    instanceNodes,
  ] of instanceNodesByOriginalNodeId.entries()) {
    const originalNode = allNodesDict[originalNodeId]
    if (!originalNode) {
      continue
    }

    const originalNodeParentPage =
      nodeTreeStructureInfo.getParentPage(originalNodeId)
    const originalNodeParentNode =
      nodeTreeStructureInfo.getParentNode(originalNodeId)
    const instanceNodeIdsToInsert: number[] = []

    // インスタンスノードの親と、マスターノードの親が食い違っている場合に、
    // マスターノードの親 = インスタンスノードを新たに挿入すべき親が、ページである可能性はない
    if (originalNodeParentPage || !originalNodeParentNode) {
      continue
    }

    for (const instanceNode of instanceNodes) {
      const instanceNodeParentPage = nodeTreeStructureInfo.getParentPage(
        instanceNode.id,
      )
      if (instanceNodeParentPage) {
        // インスタンスノードの親はページであるが、マスターノード親はページではないケース
        nodeTreeStructureInfo.deleteFromParentNodes(instanceNode.id)
        instanceNodeIdsToInsert.push(instanceNode.id)
        continue
      }

      const instanceNodeParentNode = nodeTreeStructureInfo.getParentNode(
        instanceNode.id,
      )
      if (instanceNodeParentNode) {
        if (originalNodeParentNode.id === instanceNodeParentNode.id) {
          continue
        }

        nodeTreeStructureInfo.deleteFromParentNodes(instanceNode.id)
        instanceNodeIdsToInsert.push(instanceNode.id)
      }
    }

    nodeTreeStructureInfo.insertAtOriginalNodeSibling(
      originalNodeId,
      instanceNodeIdsToInsert,
    )
  }

  return {
    resultPagesDict: nodeTreeStructureInfo.resultPagesDict,
    resultNodesDict: nodeTreeStructureInfo.resultNodesDict,
  }
}

class NodeTreeStructureInfo {
  private readonly parentPageIdByChildNodeId: Map<number, number>
  private readonly parentNodeIdByChildNodeId: Map<number, number>
  private readonly idPathToTheNodesByOriginalNodeUUID: Map<
    string,
    IdPathToTheNode[]
  >
  private readonly depthFirstOrderedNodes: ReportNodeSchema[]

  private readonly mutablePagesDict: ReportPagesDict
  private readonly mutableNodesDict: ReportNodesDict

  constructor(allPagesDict: ReportPagesDict, allNodesDict: ReportNodesDict) {
    const parentPageIdByChildNodeId = new Map<number, number>()
    const parentNodeIdByChildNodeId = new Map<number, number>()
    const idPathToTheNodesByOriginalNodeUUID = new Map<
      string,
      IdPathToTheNode[]
    >()
    const depthFirstOrderedNodes: ReportNodeSchema[] = []

    const constructMapsRecursively = (
      node: ReportNodeSchema,
      idPathToTheNode: number[],
    ) => {
      depthFirstOrderedNodes.push(node)
      const currentNodeId = node.id
      const currentIdPathToTheNode = [...idPathToTheNode, node.id]

      if (isRepeatedSectionMaster(node) && node.uuid) {
        computeIfAbsent(
          idPathToTheNodesByOriginalNodeUUID,
          node.uuid,
          () => [],
        ).push(currentIdPathToTheNode)
      }

      for (const childNodeId of node.nodes) {
        parentNodeIdByChildNodeId.set(childNodeId, currentNodeId)
        const childNode = allNodesDict[childNodeId]
        if (!childNode) {
          continue
        }

        constructMapsRecursively(childNode, currentIdPathToTheNode)
      }
    }
    for (const page of Object.values(allPagesDict)) {
      const pageId = page.id
      if (!pageId || !page.nodes) {
        continue
      }
      for (const childNodeId of page.nodes) {
        parentPageIdByChildNodeId.set(childNodeId, pageId)
        const childNode = allNodesDict[childNodeId]
        if (!childNode) {
          continue
        }
        constructMapsRecursively(childNode, [])
      }
    }

    this.parentPageIdByChildNodeId = parentPageIdByChildNodeId
    this.parentNodeIdByChildNodeId = parentNodeIdByChildNodeId
    this.idPathToTheNodesByOriginalNodeUUID = idPathToTheNodesByOriginalNodeUUID
    this.depthFirstOrderedNodes = depthFirstOrderedNodes
    this.mutablePagesDict = structuredClone(allPagesDict)
    this.mutableNodesDict = structuredClone(allNodesDict)
  }

  getParentPage(nodeId: number) {
    const parentPageId = this.parentPageIdByChildNodeId.get(nodeId)
    if (!parentPageId) return
    return this.mutablePagesDict[parentPageId]
  }

  getParentNode(nodeId: number) {
    const parentNodeId = this.parentNodeIdByChildNodeId.get(nodeId)
    if (!parentNodeId) return
    return this.mutableNodesDict[parentNodeId]
  }

  deleteFromParentNodes(childNodeId: number) {
    const parentNode = this.getParentNode(childNodeId)
    if (parentNode) {
      parentNode.nodes = parentNode.nodes.filter(
        nodeId => nodeId !== childNodeId,
      )
      return
    }
    const parentPage = this.getParentPage(childNodeId)
    if (parentPage && parentPage.nodes) {
      parentPage.nodes = parentPage.nodes.filter(
        nodeId => nodeId !== childNodeId,
      )
    }
  }

  getIdPathTo(originalNodeUuid: string) {
    return this.idPathToTheNodesByOriginalNodeUUID.get(originalNodeUuid)
  }

  insertAtOriginalNodeSibling(
    originalNodeId: number,
    nodeIdsToInsert: number[],
  ) {
    const originalNodeParentPage = this.getParentPage(originalNodeId)
    const originalNodeParentNode = this.getParentNode(originalNodeId)

    if (originalNodeParentPage) {
      insertAtOriginalNodeSibling(
        originalNodeParentPage,
        originalNodeId,
        nodeIdsToInsert,
      )
    }
    if (originalNodeParentNode) {
      insertAtOriginalNodeSibling(
        originalNodeParentNode,
        originalNodeId,
        nodeIdsToInsert,
      )
    }
  }

  get orderedNodes() {
    return this.depthFirstOrderedNodes
  }

  get resultPagesDict() {
    return this.mutablePagesDict
  }

  get resultNodesDict() {
    return this.mutableNodesDict
  }
}

const insertAtOriginalNodeSibling = (
  parent: { nodes?: number[] },
  originalNodeId: number,
  nodeIdsToInsert: number[],
) => {
  if (!parent.nodes) {
    return
  }
  const indexOfOriginalNode = parent.nodes.findIndex(
    nodeId => nodeId === originalNodeId,
  )
  parent.nodes.splice(indexOfOriginalNode + 1, 0, ...nodeIdsToInsert)
}
