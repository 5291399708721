import {
  AddReportAttachmentFileRequest,
  AddReportCommentRequest,
  DeleteReportAttachmentFileRequest,
  GetReportRequest,
  Report,
  ReportsApi,
  UpdateReportStatusRequest,
} from '@ulysses-inc/harami_api_client'
import { normalize } from 'normalizr'
import { call, put } from 'redux-saga/effects'
import { ReportResultSchema } from 'src/exShared/types/types'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../../middleware/saga/baseClient'
import { handleHTTPError } from '../../../middleware/saga/handleHttpError'
import { uploadAttachmentFilesRequestByFiles } from '../../attachmentFiles/sagas'
import interceptionsActions from '../../interceptions/actions'
import * as actions from '../actions'
import { ReportPagesEntities, SchemaReportPages } from '../schemas'
import { fixPositionsOfRepeatedSectionInstances } from './fixPositionsOfRepeatedSectionInstances'

const baseClient = new BaseClient()

const getReportRequest = (req: GetReportRequest) => {
  return baseClient
    .getApi(ReportsApi)
    .getReport(req)
    .then(report => report)
    .catch(handleHTTPError)
}

const addReportCommentRequest = (req: AddReportCommentRequest) => {
  return baseClient
    .getApi(ReportsApi)
    .addReportComment(req)
    .catch(handleHTTPError)
}

const deleteReportAttachmentFileRequest = (
  req: DeleteReportAttachmentFileRequest,
) => {
  return baseClient
    .getApi(ReportsApi)
    .deleteReportAttachmentFile(req)
    .catch(handleHTTPError)
}

const updateReportStatusRequest = (req: UpdateReportStatusRequest) => {
  return baseClient
    .getApi(ReportsApi)
    .updateReportStatus(req)
    .catch(handleHTTPError)
}

const addReportAttachmentFileRequest = (
  req: AddReportAttachmentFileRequest,
) => {
  return baseClient
    .getApi(ReportsApi)
    .addReportAttachmentFile(req)
    .catch(handleHTTPError)
}

const createReportResultSchema = (
  // FIXME: 後続の処理は normalize された report の型を信じているが、その実は any である
  //        このため、この state を参照する箇所において、ランタイムエラーが発生している
  //        e.g. https://kaminashi.atlassian.net/browse/HPB-3412
  report: any,
) => {
  const normalized = normalize<any, ReportPagesEntities, Array<number>>(
    report.pages,
    SchemaReportPages,
  )
  const { resultPagesDict, resultNodesDict } =
    fixPositionsOfRepeatedSectionInstances(
      normalized.entities.pages,
      normalized.entities.nodes,
    )
  const reportResultSchema: ReportResultSchema = {
    id: report.id,
    name: report.name,
    place: report.place,
    uuid: report.uuid,
    reportId: report.uuid,
    templateId: report.templateId,
    templates: report.templates,
    // TODO: この関数の引数 report の型が any ではなくなったら `|| {}` を外す
    nodes: resultNodesDict || {},
    pageIds: normalized.result,
    pages: resultPagesDict,
    manualMemos: report.manualMemos,
    images: report.images,
    hints: report.hints,
    comment: report.comment.comment,
    schedules: report.schedules,
    assignee: report.assignee,
    reportDate: new Date(report.reportDate),
    scorePercentage: report.scorePercentage,
    status: report.status,
    isInvalid: report.isInvalid,
    attachmentFiles: report.attachmentFiles,
    approvals: report.approvals,
    approvalFlows: report.approvalFlows,
    isShowScore: report.isShowScore,
    isEditable: report.isEditable,
    isExcelConversion: report.isExcelConversion,
    isHideQuestionOptions: report.isHideQuestionOptions,
    isAudit: report.isAudit,
    isKeyboard: report.isKeyboard,
    layoutType: report.layoutType,
    icons: report.icons,
    improve: report.improve,
    linkedIndicatedIssues: report.linkedIndicatedIssues,
    nodePathInfos: {
      uuidPaths: {},
      idPaths: {},
    },
    logicInfos: {
      nodeTable: {},
      visibleGridColumns: {},
    },
  }
  return reportResultSchema
}

function* getReportResult(
  action: ReturnType<typeof actions.reportResult.getReportResult>,
) {
  try {
    // API側でreportId降順にして2つだけレスポンスがある
    const latestReport: Report = yield call(getReportRequest, {
      reportId: action.reportId,
    })
    const latestResult = createReportResultSchema(latestReport)
    yield put(actions.reportResult.getSuccessReportResult(latestResult, {}))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.reportResult.getErrorReportResult(error))
  }
}

function* addReportComment(
  action: ReturnType<typeof actions.reportResult.addReportComment>,
) {
  try {
    yield call(addReportCommentRequest, {
      reportId: action.reportId,
      reportComment: { comment: action.comment },
    })
    yield put(actions.reportResult.addSuccessReportComment(action.comment))
    yield put(
      notificationServiceActions.showNotification({
        message: 'コメントの登録に成功しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.reportResult.addErrorReportComment(error))
  }
}

function* uploadReportAttachmentFile(
  action: ReturnType<typeof actions.reportResult.uploadReportAttachmentFile>,
) {
  try {
    const attachmentFiles = yield call(uploadAttachmentFilesRequestByFiles, [
      action.file,
    ])
    yield put(
      actions.reportResult.addReportAttachmentFile(
        action.reportId,
        attachmentFiles[0],
      ),
    )
  } catch (error) {
    yield put(
      notificationServiceActions.showNotification({
        message: 'ファイルのアップロードに失敗しました',
        notificationType: 'error',
      }),
    )
  }
}

function* addReportAttachmentFile(
  action: ReturnType<typeof actions.reportResult.addReportAttachmentFile>,
) {
  try {
    yield call(addReportAttachmentFileRequest, {
      reportId: action.reportId,
      attachmentFile: { uuid: action.file.uuid },
    })
    yield put(
      actions.reportResult.addSuccessReportAttachmentFile(
        action.reportId,
        action.file,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.reportResult.addErrorReportAttachmentFile(error))
  }
}

function* deleteReportAttachmentFile(
  action: ReturnType<typeof actions.reportResult.deleteReportAttachmentFile>,
) {
  try {
    yield call(deleteReportAttachmentFileRequest, {
      reportId: action.reportId,
      fileId: action.fileId,
    })
    yield put(
      actions.reportResult.deleteSuccessReportAttachmentFile(
        action.reportId,
        action.fileId,
      ),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.reportResult.deleteErrorReportAttachmentFile(error))
  }
}

function* updateReportStatus(
  action: ReturnType<typeof actions.reportResult.updateReportStatus>,
) {
  try {
    yield call(updateReportStatusRequest, {
      reportId: action.reportId,
      reportStatus: { status: action.status },
    })
    yield put(actions.reportResult.updateSuccessReportStatus())
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.reportResult.updateErrorReportStatus(error))
  }
}

export {
  addReportAttachmentFile,
  addReportComment,
  deleteReportAttachmentFile,
  getReportResult,
  updateReportStatus,
  uploadReportAttachmentFile,
}
