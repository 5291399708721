import { SendEmail } from '@ulysses-inc/harami_api_client'
import { getBasePath } from 'src/config'
import dateUtil from 'src/exShared/util/date'
import { appEnv } from 'src/util/appEnv'

const basePath = getBasePath()

type Report = {
  name: string
  assigneeName: string
  placeName: string
  hasDeviate: boolean
  url: string
}

export const createApprovalSendEmail = (
  userUUIDs: string[],
  report: Report,
): SendEmail => {
  return {
    subject: `[カミナシレポート]「${report.name}」の承認依頼が届きました`,
    content:
      `■以下のレポートの承認依頼が届いています。\n` +
      `レポート名：${report.name}\n` +
      `対象現場名：${report.placeName}\n` +
      `レポート作成者：${report.assigneeName}\n` +
      `逸脱回答の有無：${report.hasDeviate ? 'あり' : 'なし'}\n` +
      `対象レポートのURL：\n${report.url}\n\n` +
      `ー\n` +
      `※本メールは、配信専用のため返信できません。予めご了承ください。\n`,
    userUUIDs: userUUIDs,
  }
}

export const createRemandSendEmail = (
  url: string,
  comment: string,
  userUUIDs: string[],
  reportName: string,
  placeName: string,
  reportDate: Date,
  remandedUserName: string,
): SendEmail => {
  const content =
    `■以下のレポートの承認が差し戻されました。\n` +
    `レポート名：${reportName}\n` +
    `実施日：${dateUtil.formatYYYYMMDD_JP(reportDate)}\n` +
    `対象現場名：${placeName}\n` +
    `差し戻したユーザー名：${remandedUserName} \n` +
    `差し戻しコメント：${comment}\n` +
    `対象レポートのURL：${url}\n\n` +
    `ー\n` +
    `※本メールは、配信専用のため返信できません。予めご了承ください。`
  return {
    subject: `[カミナシレポート]「${reportName}」が差し戻されました`,
    content: content,
    userUUIDs: userUUIDs,
  }
}

export const createImproveSendEmail = (
  improveUUID: string,
  userUUIDs: string[],
  message: string,
) => {
  const path = appEnv.isLocal
    ? 'http://localhost:3000'
    : basePath.replace('api.', '')
  const url = `${path}/improves/${improveUUID}`
  return {
    subject: '[カミナシレポート]監査結果の共有',
    content: `${message}\n\n■以下のURLにアクセスして詳細を確認してください。\n${url}\n\n# 本メールはシステムにより自動送信されています。`,
    userUUIDs: userUUIDs,
  }
}

export const createImproveReviseSendEmail = (
  improveUUID: string,
  userUUIDs: string[],
  message: string,
) => {
  const path = appEnv.isLocal
    ? 'http://localhost:3000'
    : basePath.replace('api.', '')
  const url = `${path}/improves/${improveUUID}`
  return {
    subject: '[カミナシレポート]監査改善結果の共有',
    content: `${message}\n\n■以下のURLにアクセスして詳細を確認してください。\n${url}\n\n# 本メールはシステムにより自動送信されています。`,
    userUUIDs: userUUIDs,
  }
}
